// NOTE: Custom code

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Why isn't this part of javascript as a language?
function getQueryString( field, url ) {
  var href = url ? url : window.location.href;
  var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
  var string = reg.exec(href);
  return string ? decodeURI(string[1].replace(/\+/g,' ')) : null;
}

function updateFavorite(__btn, url) {
  var btn = $(__btn).find('span')
  var icon = $(btn).find('i');
  var cnt = $(btn).find('span');
  var c_orig = icon.attr('class');

  icon.attr('class', 'fa fa-spinner fa-spin');

  $.ajax({
    url:  url,
    dataType: "json",
    data: {},
    method: 'PATCH',
    success: function(data) {
      cnt.text(data.count);

      if (data.favorited) {
        $(btn).addClass('tag-danger');
        $(btn).removeClass('tag-default');
        icon.attr('class', 'fa-solid fa-heart');
      } else {
        icon.attr('class', 'fa-regular fa-heart');
        $(btn).removeClass('tag-danger');
        $(btn).addClass('tag-default');
      }
    },
    error: function() {
      icon.attr('class', c_orig);
    }
  });
}

$("#notice-alert").fadeTo(4000, 500).slideUp(500, function() {
  $("#notice-alert").alert('close');
});
