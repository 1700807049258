/* eslint no-console:0 */

//= require bootstrap.colorpickersliders

require("@rails/ujs").start()
require("turbolinks").start()
require('tether')
require("@popperjs/core")
require('data-confirm-modal')

import "bootstrap/dist/js/bootstrap";

// Local code
import './global.js';

// Core stylesheet
require("../stylesheets/application.scss")

import '@fortawesome/fontawesome-free/js/all';
import "@fortawesome/fontawesome-free/css/all.css";
